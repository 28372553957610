#small-form-heading {
  position: relative;
  overflow: hidden;
  .heading {
    background: #c29524;
    box-shadow: 0px 10px 20px rgba(21, 39, 75, 0.25);
    height: 75px;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #15274b;
  }
  .back-heading {
    height: 30px;
    font-size: 20px;
  }
  .ellipse17 {
    position: absolute;
    width: 86px;
    height: 86px;
    left: -7%;
    top: 30%;
    background: #f5c654;
    opacity: 0.2;
    transform: rotate(-90deg);
    border-radius: 50%;
  }
  .rectangle30 {
    position: absolute;
    width: 64.19px;
    height: 28.8px;
    left: 64%;
    bottom: 15%;
    background: #f5c654;
    opacity: 0.2;
    transform: rotate(-116.46deg);
  }
  .ellipse18 {
    position: absolute;
    width: 86px;
    height: 86px;
    right: -7%;
    top: -55%;
    background: #f5c654;
    opacity: 0.2;
    transform: rotate(-90deg);
    border-radius: 50%;
  }
  .rectangle31 {
    position: absolute;
    width: 64.19px;
    height: 28.8px;
    left: 20%;
    top: 0%;
    background: #f5c654;
    opacity: 0.2;
    transform: rotate(-116.46deg);
  }
  @media (min-width: 800px) {
    display: none;
  }
  .back-heading {
    height: 35px;
    font-size: 18px;
    line-height: 20px;
  }
}
