.flex-box-check {
  display: flex;
  justify-content: left;
  align-items: end !important;
}

.label-text-check {
  font-size: 14px;
  margin-right: 10px;
}
.margin-label-check {
  margin-top: 13px;
}

.checkBox {
  display: flex;
  justify-content: left;
  align-items: center;
  color: #9a9a9a;
}

.MuiCheckbox-root {
  padding-left: 0px;
}

.error-text-checkbox {
  font-size: 13px;
  color: red;
  margin-bottom: 10px;
  text-align: end;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #e14eca !important;
}
