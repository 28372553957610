#select-tags {
  position: relative;
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
    padding: 0;
    padding-left: 30px;
    margin-bottom: 10px;
  }
  .flight-icon {
    position: absolute;
    top: 20px;
    left: 15px;
    color: white;
    z-index: 1;
  }
}
#added-airports {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  .source-des-box {
    background: #ffffff;
    border: 0.5px solid #484848;
    box-sizing: border-box;
    border-radius: 50px;
    margin: 2px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #15274b;
  }
  .name-overflow {
    text-overflow: ellipsis;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
  }
  .close-icon {
    font-size: 12px;
    color: #6a6c68;
    padding-right: 5px;
    cursor: pointer;
  }
  .close-icon:hover {
    color: #15274b;
  }
}

.auto-option {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  .flight-icon {
    width: 1em;
    height: 1em;
    margin: 0px 5px;
    fill: #6a6c68;
  }
  .city {
    font-size: 1em;
    color: #6a6c68;
    margin: 0px 5px;
  }
  .name {
    color: #a8a8a8;
    font-size: 1em;
  }
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
#grouped-native-select {
  padding-left: 2rem;
  option {
    color: black;
  }
}

.trending-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
