@font-face {
  font-family: lato;
  src: url("./assets/fonts/lato/Lato-Regular.ttf");
}
body {
  margin: 0;
  font-family: lato;
  background: #f4f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.root-container {
  margin-left: 70px;
}

@media (max-width: 800px) {
  .root-container {
    margin-left: 0px;
    margin-top: 70px;
  }
}

.extra-txt {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
input::placeholder {
  color: white !important;
  font-size: 0.8em;
  opacity: 1 !important;
}
textarea::placeholder {
  color: white !important;
  font-size: 0.8em;
  opacity: 1 !important;
}
textarea {
  padding: 8px 0px !important;
}
input {
  font-size: 0.8em;
}
.back-btn {
  margin: 10px 5px 0px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-tel-input .country-list {
  color: #4e4e4e !important;
}
