#table {
  .display-flex {
    display: flex;
  }
  .t-btn {
    position: relative;
    width: fit-content;
    padding: 5px;
    margin: 0px 2px;
    height: auto;
  }
  .no-data {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    opacity: 0.5;
    padding: 25px 0px;
    border-bottom: 1px solid gray;
  }
  #search {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    // input {
    //   box-shadow: 0px 0px 1px -1px rgba(0, 0, 0, 0.2),
    //     0px 0px 1px 0px rgba(0, 0, 0, 0.14), 1px 0px 3px 0px rgba(0, 0, 0, 0.12);
    //   border: 1px solid #e0e0e0;
    //   border-radius: 4px;
    //   padding: 10px 20px;
    // }
    input::placeholder {
      color: gray !important;
    }
  }
}
