#my-trips {
  margin: 10px 0px;
  background: #808080b5;
  border-radius: 4px;
  .open {
    border-radius: 4px 4px 0px 0px !important;
    border-bottom: 0.5px solid #f7f7f78a;
    .arrow-down {
      border-radius: 0px 4px 0 0 !important;
    }
  }
  .trip-root {
    width: 100%;
    display: table;
    background: rgba(21, 39, 75, 0.15);
    border-radius: 4px;
    color: white;

    .s-des {
      vertical-align: middle;
      display: table-cell;
      font-weight: 800;
      font-size: 18px;
      line-height: 26px;
      padding: 12px 20px;
    }
    .cancel {
      display: table-cell;
      height: -webkit-fill-available;
      height: -moz-available;
      vertical-align: middle;
      text-align: center;
      width: 45px;
      cursor: pointer;
    }
    .arrow-down {
      display: table-cell;
      background: transparent;
      justify-content: center;
      vertical-align: middle;
      border-radius: 0 4px 4px 0;
      padding: 12px;
      cursor: pointer;
      text-align: center;
      width: 25px;
    }
  }
  .trip-details {
    display: flex;
    padding: 10px;
    background: rgba(21, 39, 75, 0.15);
    border-radius: 0px 0px 4px 4px;
    color: white;
    justify-content: space-between;
    .details-root {
      margin: 0px 5px;
    }
    .heading {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      margin: 7px 0px;
    }
    .value {
      .dot {
        background: white;
        width: 3px;
        height: 3px;
        margin: 0px 4px;
        border-radius: 50%;
      }
      font-size: 10px;
      line-height: 12px;
      margin: 5px 0px;
      display: flex;
      align-items: center;
    }
  }
}
.confirmation-root {
  .confirmation-container {
    overflow: auto;
    .con-container {
      margin: 20px 20px 0px;
      background: rgba(21, 39, 75, 0.15);
      border-radius: 4px;
    }
    .con-root {
      -webkit-backdrop-filter: blur(40px);
      backdrop-filter: blur(40px);
      background: radial-gradient(
        50% 138.08% at 50% 50%,
        rgba(255, 255, 255, 0.75) 0%,
        rgba(255, 255, 255, 0.15) 100%
      );
      box-shadow: 10px 0px 20px rgba(21, 39, 75, 0.25);
      max-height: calc(100vh - 168px);
      min-height: calc(100vh - 168px);
      overflow-y: auto;
    }
    .title {
      color: white;
      margin-left: 15px;
    }
    .flight-details-container {
      background: rgb(143, 145, 150);
      border-radius: 4px;
      margin: 15px 0px;
      color: white;
      //   padding: 10px;
      .upper {
        display: table;
        width: 100%;
        border-bottom: 0.5px solid #f3efef61;
        .box {
          display: table-cell;
          vertical-align: middle;
        }
        .btn-box {
          text-align: end;
          padding: 15px 0px 10px 0px;
        }
        .btn {
          background: #15274b;
          border-radius: 4px 0px 0px 4px;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          padding: 5px 20px;
        }
        .flight {
          padding: 10px 20px;
          .code {
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
          }
          .duration {
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
      .lower {
        .m-0 {
          margin: 0px !important;
        }
        .fixed-width {
          width: 38%;
        }
        .align-right {
          text-align: right;
        }
        display: flex;
        padding: 15px;
        .time {
          font-weight: 600;
          line-height: 22px;
        }
        .dots {
          width: 24%;
          margin: 0px 5%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .date {
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
        }
        .name {
          font-size: 8px;
          line-height: 15px;
        }
        .duration {
          font-size: 12px;
          line-height: 16px;
          text-align: center;
        }
      }
      .layover {
        margin: 0px 15px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        display: flex;
        padding: 10px 0px;
        align-items: center;
        font-size: 10px;
        line-height: 12px;
        .dot {
          border: 2px solid;
          margin: 0px 15px;
          display: flex;
          align-items: center;
          border-radius: 50%;
        }
      }
    }
    #passenger-full-details {
      display: table;
      color: white;
      margin: 15px;
      background: rgba(21, 39, 75, 0.15);
      border-radius: 4px;
      width: calc(100% - 60px);
      padding: 15px;
    }
    .end {
      text-align: end;
      padding-bottom: 5px;
    }
    .p-box {
      display: table-cell;
      vertical-align: middle;
      .small-txt {
        font-size: 12px;
      }
      .primary-div {
        padding: 10px;
        background: #184090;
        border-radius: 4px;
        font-size: 8px;
        text-transform: capitalize;
      }
    }
  }

  #details {
    background: radial-gradient(
      50% 138.08% at 50% 50%,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0.15) 100%
    );
    box-shadow: inset 1px -20px 20px 0px rgba(21, 39, 75, 0.25);
    backdrop-filter: blur(40px);
    border-radius: 0px;
    .overflow {
      max-height: calc(100vh - 225px);
      overflow-y: auto;
    }
  }
  @media screen and (max-width: 600px) {
    .confirmation-container {
      margin: 0px 8px !important;
    }
  }
  @media screen and (max-width: 800px) {
    .confirmation-container {
      background: transparent;
      margin-left: 0px;
      height: auto;
    }
    .width100 {
      width: 100%;
    }
    .confirmation-container .con-root {
      max-height: calc(100vh - 215px);
      min-height: calc(100vh - 215px);
    }
    #details .overflow {
      max-height: calc(100vh - 275px);
    }
  }
}
.book-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #15274b;
  box-shadow: 0px 10px 20px rgba(21, 39, 75, 0.25);
  color: white;
  font-weight: 600;
  padding: 15px 0px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.mobile-book-btn {
  display: none;
  z-index: 999;
  background: #6a6c68;
  box-shadow: 0px -10px 20px rgba(21, 39, 75, 0.25);
  border-radius: 4px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .confirmation-root
    .confirmation-container
    .flight-details-container
    .upper
    .flight
    .duration {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    display: none;
  }
  .confirmation-root
    .confirmation-container
    .flight-details-container
    .upper
    .flight
    .duration {
    display: none;
  }
  .confirmation-root .confirmation-container .con-root {
    max-height: calc(100vh - 265px);
    padding-bottom: 50px;
    min-height: calc(100vh - 265px);
  }
  .mobile-book-btn {
    display: block;
  }
  .confirmation-root #details {
    margin: 0px 10px;
    border-radius: 10px;
  }
  .confirmation-container {
    border-radius: 10px 10px 0px 0px;
    .con-container {
      margin: auto !important;
    }
  }
}
