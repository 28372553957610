.add-btn {
  background: #15274b !important;
  color: white !important;
  font-weight: bold !important;
}
.form-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
td > img {
  width: 10rem;
  border: 3px solid #dedede;
  border-radius: 5px;
  height: 5rem;
}
td > img:hover {
  transform: scale(2);
}
#add-tags {
  display: -webkit-box;
  align-items: center;
  background: #0000004f;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.55);
  .rightPanel-container {
    overflow: auto;
    width: 100%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    background: radial-gradient(
      50% 138.08% at 50% 50%,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0.15) 100%
    );
    box-shadow: 20px 30px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
  }

  .rightPanel {
    margin: auto;
    padding: 0px 20px;
    color: white;
    width: 80%;
  }

  .title {
    margin-top: 10px;
    font-size: 32px;
    text-align: center;
    font-weight: 500;
    color: #162b5a;
  }

  .bottomSection {
    margin: 30px 0px;
    text-align: center;
    display: flex;
    justify-content: space-around;
  }
  .line {
    margin: 15px 0px;
    box-shadow: inset 0px 0px 0px 1px #b9b9c6;
    height: 1px;
    background: linear-gradient(#929eaa 0%, #cacaca 50.68%, #929eaa 100%);
  }
}
