.transparent-button {
	height: 40px;
	width: 180px;
	background-color: transparent;
	border: 1px solid rgb(22, 43, 90);
	color: #15274b;
	border-radius: 3px;
	text-transform: uppercase;
	font-size: 14px;
	z-index: 1;
}
.filled-button {
	height: 40px;
	width: 180px;
	background-color: rgb(22, 43, 90);
	border: 1px solid rgb(22, 43, 90);
	color: white;
	border-radius: 3px;
	text-transform: uppercase;
	font-size: 14px;
}

.transparent-button:hover {
	cursor: pointer;
}
.transparent-button:focus {
	outline: none;
}

.filled-button:focus {
	outline: none;
}
.filled-button:hover {
	cursor: pointer;
}