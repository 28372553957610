.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eee;
  position: relative;
  padding: 20px 0;

}

.container input {
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  width: 80%;
  color: black;
  margin: 10px 0;
  border: 1px solid #d4d4d4;
}
input:focus{
  outline: none;
}
.grid{
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  /* height: calc(100vh - 100px); */
  overflow: auto;
  width: 80%;
  margin: auto;
  border: 1px solid #eee;
  padding: 10px;
  background: white;
  /* used this */
  min-height: 500px;
  max-height: 500px;
}
.grid .tile {
  border: 1px solid #eee;
  cursor: pointer;
/* used this style */  
  display: flex;  
  height: 150px; 
  overflow: hidden;
}
.searchbar { 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
}
.searchbtn {
  height: 52px;
  background: #c29524;
  border-radius: 3px;
  margin: 0px 5px;
  color: white;
  width: 150px;
  font-size: 16px;
  cursor: pointer;
  border: none;

}

#image-input {
  display: flex;
  align-items: center;
  color: black;
  border: 1px solid #b5b7bd;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  span {
    margin-left: 10px;
  }

}
.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

#image-view {
  img {
    width: 100%;
    height: 100%;
  }
  .grid{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    /* height: calc(100vh - 100px); */
    overflow: auto;
    width: 80%;
    margin: auto;
    border: 1px solid #eee;
    padding: 10px;
    background: white;
    /* used this */
    min-height: 500px;
    max-height: 500px;
  }
  .grid .tile {
    border: 1px solid #eee;
    cursor: pointer;
  /* used this style */  
    display: flex;  
    // width: 180px;
    height: 180px; 
    overflow: hidden;
  }
  .searchbar { 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }
  .searchbtn {
    height: 42px;
    background: #162b5a;
    border:1px solid #162b5a;
    margin:0px 5px;
    color: white;
    width: 150px;
    font-size: 16px;
    cursor: pointer;
  }
}
#g-image-picker{
  #image-input {
    position: relative;
    background: rgba(21, 39, 75, 0.15);
    border-radius: 4px;
    margin: 10px 0px !important;
    padding: 12px;
    .img {
      margin-left: calc(0% + 30px);
    }
    .icon {
      position: absolute;
      top: calc(100% - 35px);
      left: 15px;
    }
    .text{
      padding-left: 33px;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  #image-view {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .container {
      width: 100%;
      background: white;
      height: 200px;
      border-radius: 5px;
      border: 1px solid gray;
    }
    .container::before {
      content: "";
      background: rgba(0, 0, 0, 0.63);
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      transition: opacity 0.5s;
    }
    .container:hover::before {
      opacity: 0.5;
      transition: opacity 0.5s;
    }
    .remove {
      opacity: 0;
      position: absolute;
      top: -5px;
      right: 10px;
      transition: opacity 0.5s, top 0.5s;
      color: #15274b;
      padding: 5px;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 0.5s;
    }
    .remove:hover {
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.14);
      transition: background-color 0.5s;
    }
    .container:hover .remove {
      top: 10px;
      opacity: 1;
      transition: opacity 0.5s, top 0.5s;
    }
  }
}