.trip-root-container {
  margin: 20px 20%;
  background: #80808036;
  padding: 20px;
  border-radius: 10px;
  .btn-container {
    display: flex;
    justify-content: space-between;
  }
}
