#full-loader {
  .MuiPaper-root {
    background-color: transparent !important;
  }
  .loader {
    min-height: auto;
  }
  .fullBlock {
    height: 100%;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .loader-color {
    color: #c29524;
  }
}
