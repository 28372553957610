#airports {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  .tag-name {
    text-transform: capitalize;
  }
  img {
    height: 4rem;
    width: 8rem;
    padding: 10px;
    cursor: pointer;
    transition: height 1s, width 1s;
  }
  img:hover {
    height: 10rem;
    width: 20rem;
    transition: height 1s, width 1s;
  }
}
.Primary {
  background: #184090;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
  color: white;
  padding: 5px;
  width: fit-content;
}
