@media (min-width: 800px) {
  .status-container {
    display: none;
  }
}
.modal {
  overflow: auto;
  outline: -webkit-focus-ring-color auto 0px !important;
}
.modal:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.sign-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;
  outline: none;
}

.flexBox-root {
  color: #15274b;
  width: 70%;
  border-radius: 10px;
  margin: 40px 0px;
  max-width: 600px;
}
.close-root {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: black;
  float: right;
  margin: -23px;
  text-align: center;
  opacity: 0.8;
  cursor: pointer;
}
.close-icon {
  color: #15274b;
}
.close-btn {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ffffff 0%,
    rgba(255, 255, 255, 0.43) 100%
  );
  backdrop-filter: blur(40px);
  border-radius: 50%;
  padding: 2px;
}
@media (max-width: 800px) {
  .flexBox {
    border: 0px solid;
    background: none;
  }
  .close-root {
    display: none;
  }
  .flexBox-root {
    width: 80%;
  }
}
