.flex-box-selection {
  display: flex;
  justify-content: left;
  align-items: center !important;
}
.primary {
  position: relative;
}

.label-text-selection {
  font-size: 14px;
  margin-right: 10px;
}

.selection {
  color: #9a9a9a;
}

.error-text-selection {
  font-size: 13px;
  color: red;
  margin-bottom: 10px;
  text-align: end;
}
.icon-root {
  position: absolute;
  top: 10px;
  left: 15px;
}

.p-l-0 {
  div {
    padding-left: 0px !important;
  }
}
#demo-mutiple-name {
  padding-left: 35px;
  padding-right: 2px !important;
}
option {
  cursor: pointer;
  padding: 5px;
  color: black;
}

select {
  font-size: 0.8em;
}
