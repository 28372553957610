.primary {
  background: rgba(21, 39, 75, 0.15);
  border-radius: 4px;
  margin: 10px 2px !important;
  .MuiInput-root {
    height: 100%;
    padding: 5px 15px;
    color: #ffffff;
    font-weight: bold;
    min-height: 42px;
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none !important;
  }
}

.outlined {
  background: white;
  border: 1px solid rgba(154, 154, 154, 1) !important;
  border-radius: 6px;
  margin: 10px 0px !important;
  .MuiInput-root {
    height: 44px;
    padding: 12px 15px;
    color: rgba(154, 154, 154, 1);
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none !important;
  }
}

.dark-outlined {
  background: #1e1e24;
  border: 1px solid #2b3553 !important;
  border-radius: 6px;
  margin: 10px 0px !important;
  .MuiInput-root {
    height: 44px;
    padding: 10px 30px;
    color: rgba(255, 255, 255, 1);
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none !important;
  }
}

.error-text {
  font-size: 13px;
  color: red;
  margin-bottom: 10px;
  text-align: end;
  white-space: pre-line;
}

.container {
  width: 100%;
}

.inline-container {
  display: inline-block;
}

.label-text {
  font-size: 14px;
  margin-right: 10px;
}

.flex-box-input {
  display: flex;
  justify-content: left;
  align-items: center;
}

.left-bdr {
  div {
    input {
      border-left: 2px solid white !important;
      height: 7px;
      padding-left: 10px !important;
    }
  }
}

@media (max-width: 800px) {
  .left-bdr {
    div {
      input {
        padding-left: 5px !important;
      }
    }
  }
}
.left-bdr {
  div {
    padding: 12px 5px !important;
  }
}
