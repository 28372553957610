#sign-in {
  display: -webkit-box;
  align-items: center;
  background: #0000004f;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.55);
  .rightPanel-container {
    width: 60%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    background: radial-gradient(
      50% 138.08% at 50% 50%,
      rgba(255, 255, 255, 0.75) 0%,
      rgba(255, 255, 255, 0.15) 100%
    );
    box-shadow: 20px 30px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
  }

  .rightPanel {
    margin: auto;
    padding: 25px 20px 0px;
    color: white;
    width: 80%;
  }

  .signClass {
    margin-top: 60px;
    font-size: 32px;
    text-align: center;
    font-weight: 500;
  }

  .emailClass {
    margin-bottom: 18px;
    font-size: 12px;
    text-align: center;
    color: #717171;
  }

  .bottomSection {
    margin-bottom: 30px;
    text-align: center;
  }

  .forgotPassWord {
    text-align: end;
    font-family: Lato;
    color: #ffffff;
    font-size: 12px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .line {
    margin: 15px 0px;
    box-shadow: inset 0px 0px 0px 1px #5c5b5bd4;
    height: 1px;
    background: linear-gradient(#929eaa 0%, #cacaca 50.68%, #929eaa 100%);
  }
  .spot1 {
    position: absolute;
    width: 253px;
    height: 253px;
    left: -107px;
    top: -112px;
    background: #f5c654;
    opacity: 0.2;
    border-radius: 50%;
  }
  .spot2 {
    position: absolute;
    width: 18px;
    height: 52px;
    left: 241.89px;
    top: 92px;
    background: #f5c654;
    opacity: 0.2;
    transform: rotate(46.77deg);
  }
  .spot3 {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 32px;
    top: 394.82px;
    background: #f5c654;
    opacity: 0.2;
    transform: rotate(-26.46deg);
  }
  .back-card {
    position: absolute;
    width: 80% !important;
    top: 10px;
    left: 25px;
  }
  @media (min-width: 800px) {
    .back-card {
      display: none;
    }
  }

  @media (max-width: 800px) {
    border: 0px solid;
    background: none;
    .rightPanel-container {
      width: 100%;
      background: radial-gradient(
        226.17% 100% at 0% 0%,
        rgba(255, 255, 255, 0.55) 0%,
        rgba(255, 255, 255, 0.15) 100%
      );
      box-shadow: -10px 0px 20px rgba(21, 39, 75, 0.25);
      backdrop-filter: blur(40px);
      border-radius: 10px;
      transform: none !important;
    }
    .signClass {
      display: none;
    }
    .line {
      display: none;
    }
  }
}
