.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #fff;
}

.react-tel-input .selected-flag .arrow.up {
  border-bottom: 4px solid #fff;
}

.react-tel-input .country-list {
  max-height: 150px;
}
