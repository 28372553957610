#date-picker {
  border: 0px;
  background: transparent;
  width: 100%;
  padding: 0px;
  padding-left: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
}
input[id="date-picker"]:focus {
  outline: none !important;
}
.left-10 {
  left: 10px;
}
