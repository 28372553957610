#modal-component{
    .child-component{
        width: 70%;
        max-width: 600px;
        display: flex;
        align-items: center;
        margin: auto;
        height: 100%;
    }
    .child-component:focus{
        outline:none;
    }
}