#radio-button {
  display: flex;
  align-items: center;
  .flex-box-radio {
    display: flex;
    justify-content: left;
    align-items: end !important;
  }
  .MuiFormGroup-root {
    flex-direction: row !important;
  }

  .label-text-radio {
    margin-right: 10px;
    font-weight: bolder;
  }

  .margin-label-radio {
    margin-top: 13px;
  }

  .radio {
    color: #9a9a9a;
  }

  .error-text-radio {
    font-size: 13px;
    color: red;
    margin-bottom: 10px;
    text-align: end;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #162b5a !important;
  }
}
